import i18n from 'i18next';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {TRANSLATIONS_EN} from "./en";
import {TRANSLATIONS_DE} from "./de";


i18n.use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            "de-DE": {
                translation: TRANSLATIONS_DE
            },
            "en-US": {
                translation: TRANSLATIONS_EN
            }
        },
        fallbackLng: "de-DE",
    }).then();
