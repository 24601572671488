export const TRANSLATIONS_EN = {
    countryCode: {
        "en-US": "EN",
        "de-DE": "DE"
    },
    language: {
        current: "English",
        abridged: "EN"
    },
    base: {
        loading: "Loading...",
        noAccess: "You have no access to this service.",
        thiemeGroup: "Thieme Group",
        pageTitle: "Counter Reporting",
        yes: "Yes",
        no: "No",
        logoutButtonText: "Logout"
    },
    report: {
        ui: {
            AccordionBasic: "Report",
            AccordionAdvanced: "Report parameters",
            submitButton: "Generate",
            month: "Monthly",
            totals: "Totals",
        },
        messages: {
            generalError: "Something went wrong, please check the parameters or contact the support.",
            reportMandatory: "Report is mandatory",
        },
        basic: {
            report: "Report",
            from: "From",
            to: "To",
            organization: "Organization",
            platform: "Platform",
        },
        params: {
            period: "Period",
            dataType: "Data Type",
            sectionType: "Section Type",
            YOP: "Year of publication",
            accessType: "Access Type",
            accessMethod: "Access Method",
            metricType: "Metric Type",
            excludeMonthlyDetails: "Granularity",
            aggregateByAlt: "include field as column",
        },
    },
    footer: {
        privacy: {
            name: "Privacy",
            Url: "https://www.thieme.de/de/thieme-gruppe/privacy-policy-statement-Thieme-EN.htm",
        },
        imprint: {
            name: "Imprint",
            Url: "https://www.thieme.de/de/thieme-gruppe/Imprint-Thieme-EN.htm",
        }
    },
    apikeys: {
        heading: "SUSHI API Keys",
        createButtonText: "Create new API key",
        overview: {
            columns: {
                maskedKey: "Key",
                organisation: "Organisations",
                createdOn: "Created On",
                validUntil: "Valid Until",
                lastUse: "Last Use",
            },
            deleteButton: "Delete API Key",
            noKeysMessage: "No API Keys found."
        },
        create: {
            dialog: {
                text: "Please select the organizations for which access shall be granted.",
                selectOrganisation: {
                    label: "Selected Organizations"
                },
                buttonCreate: "Create API Key",
                buttonCancel: "Cancel",
                completed: {
                    title: "API Key successfully created",
                    text: "Your API Key has been created successfully.",
                    fieldLabel: "API Key",
                    copyButton: "Copy to clipboard",
                    closeButton: "Close",
                    copyTooltip: "Copy to clipboard",
                    warning: {
                        title: "Important Notice",
                        text: "Please save the API Key before closing this window. The API Key will be displayed in clear text only once and cannot be retrieved again after closing this window!"
                    },
                }
            }
        },
        delete: {
            dialog: {
                title: "Delete API Key",
                text: "Are you sure you want to delete the API Key?",
                confirmButton: "Delete API Key"
            }
        }
    }
};
