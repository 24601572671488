import React, {useEffect} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers/";
import deDELocale from "date-fns/locale/de";
import enUSLocale from "date-fns/locale/en-US";
import {
  accessMethods,
  accessTypes,
  allReports,
  dataTypes, defaultReportParameterFilter,
  erefComplete,
  erefDel,
  icare,
  medOneComplete,
  medOneEducation,
  metricTypes,
  osteothek,
  pharmaceuticalSubstances,
  platform, reportParameters,
  roempp,
  scienceOfSynthesis,
  sectionTypes,
  selectMenuItem,
  selectMenuItemAutocomplete,
  thiemeConnect,
  vetcenter
} from "../data/ReportTypes";
import useOrganizations from "../hooks/UseOrganizations";
import {format} from "date-fns";
import {ApiKeysClient} from "../clients/ApiKeysClient";
import {useKeycloak} from "@react-keycloak/web";

export default function Reports() {
  const {t, i18n} = useTranslation();
  const currentLocale = i18n.resolvedLanguage === "de-DE" ? deDELocale : enUSLocale;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const organizations = useOrganizations();
  const token = useKeycloak().keycloak.token;
  const granularity: selectMenuItem[] = [
    {
      name: t("report.ui.month"),
      value: "Month",
      description: null,
      children: null
    },
    {
      name: t("report.ui.totals"),
      value: "Totals",
      description: null,
      children: null
    }
  ];
  const emptyAutocomplete = {label: "", value: "", description: "", group: ""};
  const formObject: {[index: string]: any} = {
    customer_id: emptyAutocomplete,
    granularity: "Month",
    report: emptyAutocomplete,
    yop: "",
    access_type: [],
    metric_type: [],
    data_type: [],
    section_type: [],
    access_method: [],
    platform: emptyAutocomplete,
    begin_date: new Date(new Date().getFullYear(), 0, 1),
    end_date: new Date(new Date().getFullYear(), 11, 31),
    aggregateBy_yop: false,
    aggregateBy_access_type: false,
    aggregateBy_metric_type: false,
    aggregateBy_data_type: false,
    aggregateBy_section_type: false,
    aggregateBy_access_method: false
  };

  const [filteredReports, setFilteredReports] = React.useState(allReports);
  const [filteredReportParams, setFilteredReportParams] = React.useState({
    dataTypes: dataTypes.TR,
    sectionTypes: sectionTypes.TR,
    accessTypes: accessTypes.TR,
    accessMethods: accessMethods.TR,
    metricTypes: metricTypes.TR
  });
  const [reportParameterAggregations, setReportParameterAggregations] = React.useState(reportParameters.aggregations.default);
  const [form, setForm] = React.useState(formObject);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    text: "",
    severity: "info"
  } as {open: boolean, text: string, severity: AlertColor});
  const [boolStates, setBoolStates] = React.useState({
    loading: false,
    disableAdd: {
      dataType: false,
      sectionType: false,
      YOP: false,
      accessType: false,
      accessMethod: false,
      metricType: false,
      granularity: false
    }
  });
  const [transposedOrgs, setTransposedOrgs] = React.useState<selectMenuItemAutocomplete[]>([{
    label: "",
    value: "",
    description: "",
    group: ""
  }]);

  function deactivateReportParameters() {
    setBoolStates(states => ({
      ...states,
      disableAdd: {
        dataType: true,
        sectionType: true,
        YOP: true,
        accessType: true,
        accessMethod: true,
        metricType: true,
        granularity: true
      }
    }));
  }

  // organization changes
  useEffect(() => {
    if (organizations.length === 0) return;
    const transposed = organizations.map(orga => {
      return {value: orga.id, label: `${orga.name} (${orga.id})`, description: null, group: null};
    });
    setTransposedOrgs(transposed);
    setForm(form => ({...form, customer_id: transposed[0]}));
  }, [organizations]);

  // platform change
  useEffect(() => {
    setForm(form => ({...form, report: {label: "", value: "", description: "", group: ""}}));

    switch (form.platform?.value) {
      case "eref":
        setFilteredReports(erefComplete);
        break;
      case "eref-del":
        setFilteredReports(erefDel);
        break;
      case "icare":
        setFilteredReports(icare);
        break;
      case "medone":
        setFilteredReports(medOneComplete);
        break;
      case "medone-tebl":
        setFilteredReports(medOneEducation);
        break;
      case "osteothek":
        setFilteredReports(osteothek);
        break;
      case "ps":
        setFilteredReports(pharmaceuticalSubstances);
        break;
      case "roempp":
        setFilteredReports(roempp);
        break;
      case "sos":
        setFilteredReports(scienceOfSynthesis);
        break;
      case "thieme-connect":
        setFilteredReports(thiemeConnect);
        break;
      case "vetcenter":
        setFilteredReports(vetcenter);
        break;
      default:
        setFilteredReports(allReports);
    }
  }, [form.platform]);

  function mapFormReportParameterFilter(reportParameterFilter: any) {
      return {
        access_type: reportParameterFilter ? reportParameterFilter.accessType.map((x : selectMenuItem) => x.value) : [],
        metric_type: reportParameterFilter ? reportParameterFilter.metricType.map((x : selectMenuItem) => x.value) : [],
        data_type: reportParameterFilter ? reportParameterFilter.dataType.map((x : selectMenuItem) => x.value) : [],
        section_type: reportParameterFilter ? reportParameterFilter.sectionType.map((x : selectMenuItem) => x.value) : [],
        access_method: reportParameterFilter ? reportParameterFilter.accessMethod.map((x : selectMenuItem) => x.value) : [],
      }
  }

  useEffect(() => {
    function resetFormReportParameterFilters() {
      setForm(form => ({
        ...form,
        ...mapFormReportParameterFilter(defaultReportParameterFilter.default),
        yop: "",
      }));
    }

    resetFormReportParameterFilters();

    switch (form.report.group) {
      case "Title Reports":
        setFilteredReportParams(({
          dataTypes: dataTypes.TR,
          accessTypes: accessTypes.TR,
          metricTypes: metricTypes.TR,
          accessMethods: accessMethods.TR,
          sectionTypes: sectionTypes.TR
        }));
        setBoolStates(states => ({...states, disableAdd: reportParameters.disabled.TR}));
        setReportParameterAggregations(reportParameters.aggregations[form.report.value])
        break;
      case "Database Reports":
        setFilteredReportParams(({
          dataTypes: dataTypes.DR,
          accessTypes: accessTypes.DR,
          metricTypes: metricTypes.DR,
          accessMethods: accessMethods.DR,
          sectionTypes: sectionTypes.DR
        }));
        setReportParameterAggregations(reportParameters.aggregations[form.report.value])
        setBoolStates(states => ({...states, disableAdd: reportParameters.disabled.DR }));
        break;
      case "Platform Reports":
        setFilteredReportParams(({
          dataTypes: dataTypes.PR,
          accessTypes: accessTypes.PR,
          metricTypes: metricTypes.PR,
          accessMethods: accessMethods.PR,
          sectionTypes: sectionTypes.PR
        }));
        setReportParameterAggregations(reportParameters.aggregations[form.report.value])
        setBoolStates(states => ({...states, disableAdd: reportParameters.disabled.PR }));
        break;
      case "Item Reports":
        setFilteredReportParams(({
          dataTypes: dataTypes.IR,
          accessTypes: accessTypes.IR,
          metricTypes: metricTypes.IR,
          accessMethods: accessMethods.IR,
          sectionTypes: sectionTypes.IR
        }));
        setReportParameterAggregations(reportParameters.aggregations[form.report.value])
        setBoolStates(states => ({...states, disableAdd: reportParameters.disabled.IR }));
        break;
      default:
        setBoolStates(states => ({...states, disableAdd: reportParameters.disabled.default }));
        break;
    }

    setForm(form => ({
      ...form,
      ...mapFormReportParameterFilter(defaultReportParameterFilter[form.report.value]),
      yop: "",
    }));

    if (!form.report?.label.includes("Master")) {
      deactivateReportParameters();
    }
  }, [form.report]);

  useEffect(() => {
    function applyDefaultFormAggregationParams() {
      setForm(form => ({
        ...form,
        aggregateBy_yop: reportParameterAggregations.YOP.initialValue,
        aggregateBy_access_type: reportParameterAggregations.accessType.initialValue,
        aggregateBy_metric_type: reportParameterAggregations.metricType.initialValue,
        aggregateBy_data_type: reportParameterAggregations.dataType.initialValue,
        aggregateBy_section_type: reportParameterAggregations.sectionType.initialValue,
        aggregateBy_access_method: reportParameterAggregations.accessMethod.initialValue,
      }))
    }

    applyDefaultFormAggregationParams()
  }, [reportParameterAggregations]);

  const handleChange = (event: {target: {name: string, value: string}}) => {
    setForm(form => ({...form, [event.target.name]: event.target.value}));
  };

  const handleChangeCB = (event: {target: {name: string, checked: boolean}}) => {
    setForm(form => ({...form, [event.target.name]: event.target.checked}));
  };

  const parameterSelector = (labelId: string, name: string, tKey: string, items: selectMenuItem[], multiple = false, disabled = false, aggregationAllowed = false) => (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={11}>{select(labelId, name, tKey, items, multiple, disabled)}</Grid>
      <Grid item xs={1}>
        <FormControl fullWidth>
          <Checkbox name={"aggregateBy_" + name}
                    disabled={disabled || !aggregationAllowed}
                    checked={form["aggregateBy_" + name]}
                    onChange={handleChangeCB}
                    size={"medium"} title={t("report.params.aggregateByAlt")}
                    aria-label={t("report.params.aggregateByAlt")} />
        </FormControl>
      </Grid>
    </Grid>
  );

  const select = (labelId: string, name: string, tKey: string, items: selectMenuItem[], multiple = false, disabled = false) => (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{t(tKey)}</InputLabel>
      <Select
        label={t(tKey)}
        labelId={labelId}
        name={name}
        multiple={multiple}
        value={form[name]}
        disabled={disabled}
        onChange={handleChange}
      >
        {items.map(item =>
          item.value ?
            (<MenuItem key={item.name + "/" + item.value} value={item.value}>
              {item.name}
            </MenuItem>)
            :
            ([(<ListSubheader>{item.name}</ListSubheader>),
              optionSelect(item.children!)])
        )}
      </Select>
    </FormControl>);

  const optionSelect = (items: selectMenuItem[]) => (
    [items.map(item => (
      <MenuItem key={item.name + "/" + item.value} value={item.value!}>{item.name}</MenuItem>
    ))]
  );
  const datePicker = (name: string, tKey: string) => (
    <DatePicker
      label={t(tKey)}
      value={form[name]}
      onChange={(newValue) => {
        if (newValue == null) return;
        setForm(form => ({...form, [name]: newValue}));
      }}
      renderInput={(params) => <TextField fullWidth {...params} />}
    />
  );
  const autocomplete = (name: string, tKey: string, items: selectMenuItemAutocomplete[]) => (
    <FormControl fullWidth>
      <Autocomplete
        options={items}
        autoHighlight={true}
        value={form[name]}
        groupBy={(option) => option.group}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event: any, newValue) => {
          setForm(form => ({...form, [name]: newValue}));
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params}
                                                                                      label={t(tKey)} />}
      />
    </FormControl>
  );

  const generateReport = () => {
    if (boolStates.loading) return;
    setBoolStates(states => ({...states, loading: true}));

    if (form.report.value === "") {
      setBoolStates(states => ({...states, loading: false}));
      return setSnackBar(({text: t("report.messages.reportMandatory"), open: true, severity: "warning"}));
    }

    const getValue = (value: any) => {
      if (value instanceof Date) return format(value, "yyyy-MM-dd");
      if (Array.isArray(value) && value.length !== 0) return encodeURIComponent(value.join("|"));
      if (typeof value == "object") return value?.value;
      return value;
    };

    function mapAttributeToShow(k: string) {
      switch (k.split("aggregateBy_")[1]) {
        case "access_method":
          return "Access_Method";
        case "access_type":
          return "Access_Type";
        case "data_type":
          return "Data_Type";
        case "section_type":
          return "Section_Type";
        case "yop":
          return "YOP";
        default:
          return undefined;
      }
    }

    function toQueryParam(k: string, v: any) {
      return `${k}=${v}`;
    }

    const attributes_to_show =
      Object.entries(form).filter(([k, v]) => k.startsWith("aggregateBy_") && !!v)
        .map(([k]) => mapAttributeToShow(k));
    const params = Object.entries(form)
      .filter(([k, v]) => !k.startsWith("aggregateBy_") && k !== "report" && !!v)
      .concat(attributes_to_show ? Object.entries({attributes_to_show: attributes_to_show}) : [])
      .map(([k, v]) => [k, getValue(v)])
      .filter(([k, v]) => !!v && v.length > 0);

    const queryParams = params.map(([k,v])=> toQueryParam(k, v)).join('&')

    const client = new ApiKeysClient(token!);
    client.fetchReport(form.report.value, queryParams)
      .then(fetchedReport => {
        if (fetchedReport.file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          const url = window.URL.createObjectURL(
            new Blob([fetchedReport.file])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            fetchedReport.fileName
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode!.removeChild(link);

        }
      })
      .catch(err => {
        console.error("failed to fetch report", err);
        setSnackBar(({text: t("report.messages.generalError"), open: true, severity: "error"}));

      }).finally(() => {
        setBoolStates(states => ({...states, loading: false}));
      }
    );

  };

  const snackBarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar(bar => ({...bar, open: false}));
  };
  const snackBarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={snackBarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Grid container columnSpacing={5} rowSpacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Accordion defaultExpanded style={{color: theme.palette.primary.main}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="basicReportDetails">
            <Typography>{t("report.ui.AccordionBasic")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {autocomplete("customer_id", "report.basic.organization",
                  transposedOrgs)}
              </Grid>
              <Grid item xs={12}>
                {autocomplete("platform", "report.basic.platform", platform)}
              </Grid>
              <Grid item xs={12}>
                {autocomplete("report", "report.basic.report", filteredReports)}
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
                <Grid item xs={12} sm={12} md={6}>
                  {datePicker("begin_date", "report.basic.from")}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {datePicker("end_date", "report.basic.to")}
                </Grid>
              </LocalizationProvider>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Box sx={{m: 1, position: "relative"}}>
            <Button variant={"contained"} fullWidth
                    startIcon={<CloudDownloadOutlinedIcon />}
                    disabled={boolStates.loading}
                    onClick={() => generateReport()}>
              {t("report.ui.submitButton")}
            </Button>
            {boolStates.loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px"
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Accordion defaultExpanded={!isMobile} style={{color: theme.palette.primary.main}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="advancedReportDetails">
            <Typography>{t("report.ui.AccordionAdvanced")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {parameterSelector("dataTypeSelect", "data_type", "report.params.dataType", filteredReportParams.dataTypes, true, boolStates.disableAdd.dataType, reportParameterAggregations.dataType.allowed)}
              </Grid>
              <Grid item xs={12}>
                {parameterSelector("sectionTypeSelect", "section_type", "report.params.sectionType", filteredReportParams.sectionTypes, true, boolStates.disableAdd.sectionType, reportParameterAggregations.sectionType.allowed)}
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={11}>
                    <FormControl fullWidth>
                      <TextField label={t("report.params.YOP")} value={form.yop} name={"yop"}
                                 disabled={boolStates.disableAdd.YOP}
                                 onChange={handleChange} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <FormControl fullWidth>
                      <Checkbox disabled={boolStates.disableAdd.YOP || !reportParameterAggregations.YOP.allowed}
                                name={"aggregateBy_yop"}
                                checked={form.aggregateBy_yop}
                                onChange={handleChangeCB}
                                size={"medium"} title={t("report.params.aggregateByAlt")}
                                aria-label={t("report.params.aggregateByAlt")} />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {parameterSelector("accessTypeSelect", "access_type", "report.params.accessType", filteredReportParams.accessTypes, true, boolStates.disableAdd.accessType, reportParameterAggregations.accessType.allowed)}
                {reportParameterAggregations.accessType.value}
              </Grid>

              <Grid item xs={12}>
                {parameterSelector("accessMethodSelect", "access_method", "report.params.accessMethod", filteredReportParams.accessMethods, true, boolStates.disableAdd.accessMethod, reportParameterAggregations.accessMethod.allowed)}
              </Grid>

              <Grid item xs={12}>
                {parameterSelector("metricTypeSelect", "metric_type", "report.params.metricType", filteredReportParams.metricTypes, true, boolStates.disableAdd.metricType, reportParameterAggregations.metricType.allowed)}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {select("excludeMonthlyDetailsSelect", "granularity", "report.params.excludeMonthlyDetails", granularity, false, boolStates.disableAdd.granularity)}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={snackBarClose}
        action={snackBarAction}
      ><Alert onClose={snackBarClose} severity={snackBar.severity}>{snackBar.text}</Alert></Snackbar>
    </Grid>
  );
}
