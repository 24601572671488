export type selectMenuItem = {
  value: string | null;
  name: string;
  description: string | null;
  children: selectMenuItem[] | null;
}

export type selectMenuItemAutocomplete = {
  label: string;
  value: string;
  description: string | null;
  group: string | null;
}

export const allReports: selectMenuItemAutocomplete[] = [
  {
    label: "",
    value: "",
    description: "",
    group: ""
  },
  {
    label: "(TR) Title Master Report",
    value: "TR",
    description: "A customizable report detailing activity at the title level (journal, book, etc.) that allows the user to apply filters and select other configuration options.",
    group: "Title Reports"
  },
  {
    label: "(TR_B1) Book Requests (Excluding OA_Gold)",
    value: "TR_B1",
    description: "Reports on full-text activity for books, excluding Gold Open Access content, as Total_Item_Requests and Unique_Title_Requests. The Unique_Title_Requests provides comparable usage across book platforms. The Total_Item_Requests shows overall activity; however, numbers between sites will vary significantly based on how the content is delivered (e.g. delivered as a complete book or by chapter).",
    group: "Title Reports"
  },
  {
    label: "(TR_B2) Book Access Denied",
    value: "TR_B2",
    description: "Reports on Access Denied activity for books where users were denied access because simultaneous-use licenses were exceeded or their institution did not have a license for the book.",
    group: "Title Reports"
  },
  {
    label: "(TR_B3) Book Usage by Access Type",
    value: "TR_B3",
    description: "Reports on book usage showing all applicable Metric_Types broken down by Access_Type.",
    group: "Title Reports"
  },
  {
    label: "(TR_J1) Journal Requests (Excluding OA_Gold)",
    value: "TR_J1",
    description: "Reports on usage of journal content, excluding Gold Open Access content, as Total_Item_Requests and Unique_Item_Requests. The Unique_Item_Requests provides comparable usage across journal platforms by reducing the inflationary effect that occurs when an HTML full text automatically displays and the user then accesses the PDF version. The Total_Item_Requests shows overall activity.",
    group: "Title Reports"
  },
  {
    label: "(TR_J2) Journal Access Denied",
    value: "TR_J2",
    description: "Reports on Access Denied activity for journal content where users were denied access because simultaneous-use licenses were exceeded or their institution did not have a license for the title.",
    group: "Title Reports"
  },
  {
    label: "(TR_J3) Journal Usage by Access Type",
    value: "TR_J3",
    description: "Reports on usage of journal content for all Metric_Types broken down by Access_Type.",
    group: "Title Reports"
  },
  {
    label: "(TR_J4) Journal Requests by YOP (Excluding OA_Gold)",
    value: "TR_J4",
    description: "Breaks down the usage of journal content, excluding Gold Open Access content, by year of publication (YOP), providing counts for the Metric_Types Total_Item_Requests and Unique_Item_Requests. Provides the details necessary to analyze usage of content in backfiles or covered by perpetual access agreements. Note that COUNTER reports do not provide access model or perpetual access rights details.",
    group: "Title Reports"
  },
  {
    label: "(DR) Database Master Report",
    value: "DR",
    description: "A customizable report detailing activity by database that allows the user to apply filters and select other configuration options.",
    group: "Database Reports"
  },
  {
    label: "(DR_D1) Database Search and Item Usage",
    value: "DR_D1",
    description: "Reports on key Searches, Investigations and Requests metrics needed to evaluate a database.",
    group: "Database Reports"
  },
  {
    label: "(DR_D2) Database Access Denied",
    value: "DR_D2",
    description: "Reports on Access Denied activity for databases where users were denied access because simultaneous-use licenses were exceeded or their institution did not have a license for the database.",
    group: "Database Reports"
  },
  {
    label: "(PR) Platform Master Report",
    value: "PR",
    description: "A customizable report summarizing activity across a content provider’s platforms that allows the user to apply filters and select other configuration options.",
    group: "Platform Reports"
  },
  {
    label: "(PR_P1) Platform Usage",
    value: "PR_P1",
    description: "Platform-level usage summarized by Metric_Type.",
    group: "Platform Reports"
  },
  {
    label: "(IR) Item Master Report",
    value: "IR",
    description: "A granular, customizable report showing activity at the level of the item (article, chapter, media object, etc.) that allows the user to apply filters and select other configuration options.",
    group: "Item Reports"
  },
  {
    label: "(IR_A1) Journal Article Requests",
    value: "IR_A1",
    description: "Reports on journal article requests at the article level. This report is limited to content with a Data_Type of Article, Parent_Data_Type of Journal, and Metric_Types of Total_Item_Requests and Unique_Item_Requests. This Standard View must be provided only if (a) it is clear for all articles in IR whether they are journal articles or not and (b) the parent item is known for all journal articles.",
    group: "Item Reports"
  },
  {
    label: "(IR_M1) Multimedia Item Requests",
    value: "IR_M1",
    description: "Reports on multimedia requests at the item level.",
    group: "Item Reports"
  }
];
export const platform: selectMenuItemAutocomplete[] = [
  {
    label: "",
    value: "",
    description: "",
    group: ""
  },
  // {
  //     label: 'eRef (complete)',
  //     value: 'eref',
  //     description: '',
  //     group: '',
  // },
  {
    label: "eRef Lehrbücher",
    value: "eref-del",
    description: "",
    group: ""
  },
  // {
  //     label: 'I care',
  //     value: 'icare',
  //     description: '',
  //     group: '',
  // },
  // {
  //     label: 'MedOne (complete)',
  //     value: 'medone',
  //     description: '',
  //     group: '',
  // },
  {
    label: "MedOne Education",
    value: "medone-tebl",
    description: "",
    group: ""
  },
  // {
  //     label: 'Osteothek',
  //     value: 'osteothek',
  //     description: '',
  //     group: '',
  // },
  // {
  //     label: 'Pharmaceutical Substances',
  //     value: 'ps',
  //     description: '',
  //     group: '',
  // },
  {
    label: "Römpp",
    value: "roempp",
    description: "",
    group: ""
  },
  // {
  //     label: 'Science of Synthesis',
  //     value: 'sos',
  //     description: '',
  //     group: '',
  // },
  {
    label: "Thieme-Connect",
    value: "thieme-connect",
    description: "",
    group: ""
  }
  // {
  //     label: 'Vetcenter',
  //     value: 'vetcenter',
  //     description: '',
  //     group: '',
  // },
];

/* filtered reports */
export const erefComplete: selectMenuItemAutocomplete[] = allReports.filter(x => x.value === "TR_B1" || x.value === "");
export const erefDel: selectMenuItemAutocomplete[] = allReports.filter(x => x.value === "TR" || x.value.startsWith("TR_B") || x.value === "PR" || x.value === "PR_P1" || x.value === "");
export const icare: selectMenuItemAutocomplete[] = allReports;
export const medOneComplete: selectMenuItemAutocomplete[] = allReports.filter(x => x.value === "TR_B1" || x.value === "");
export const medOneEducation: selectMenuItemAutocomplete[] = allReports.filter(x => x.value === "TR" || x.value.startsWith("TR_B") || x.value === "PR" || x.value === "PR_P1" || x.value === "");
export const osteothek: selectMenuItemAutocomplete[] = allReports;
export const pharmaceuticalSubstances: selectMenuItemAutocomplete[] = allReports.filter(x => x.group === "Database Reports" || x.value === "");
export const roempp: selectMenuItemAutocomplete[] = allReports.filter(x => x.group === "Database Reports" || x.value === "PR_P1" || x.value === "PR" || x.value === "");
export const scienceOfSynthesis: selectMenuItemAutocomplete[] = allReports.filter(x => x.group === "Database Reports" || x.value === "");
export const thiemeConnect: selectMenuItemAutocomplete[] = allReports.filter(x => x.group !== "Database Reports" && x.value !== "IR_M1");
export const vetcenter: selectMenuItemAutocomplete[] = allReports;

/* Report Parameters */
const emptyEntry = {value: " ", name: " ", description: null, children: null};
const dataTypeEntries = {
  BookSegment: {
    value: "Book_Segment",
    name: "Book_Segment",
    description: null,
    children: null
  },
  Database: {
    value: "Database",
    name: "Database",
    description: null,
    children: null
  },
  Article: {
    value: "Article",
    name: "Article",
    description: null,
    children: null
  },
  Book: {
    value: "Book",
    name: "Book",
    description: null,
    children: null
  },
  Journal: {
    value: "Journal",
    name: "Journal",
    description: null,
    children: null
  },
  Multimedia: {
    value: "Multimedia",
    name: "Multimedia",
    description: null,
    children: null
  }
};
export const dataTypes = {
  TR: [
    dataTypeEntries.BookSegment,
    dataTypeEntries.Database,
    dataTypeEntries.Article,
    dataTypeEntries.Book,
    dataTypeEntries.Journal,
    dataTypeEntries.Multimedia
  ],
  PR: [
    dataTypeEntries.BookSegment,
    dataTypeEntries.Database,
    dataTypeEntries.Article,
    dataTypeEntries.Book,
    dataTypeEntries.Journal,
    dataTypeEntries.Multimedia
  ],
  IR: [
    dataTypeEntries.BookSegment,
    dataTypeEntries.Database,
    dataTypeEntries.Article,
    dataTypeEntries.Book,
    dataTypeEntries.Journal,
    dataTypeEntries.Multimedia
  ],
  DR: [
    dataTypeEntries.BookSegment,
    dataTypeEntries.Database,
    dataTypeEntries.Article,
    dataTypeEntries.Book,
    dataTypeEntries.Journal,
    dataTypeEntries.Multimedia
  ]
};
const sectionTypeEntries = {
  Article: {
    value: "Article",
    name: "Article",
    description: null,
    children: null
  },
  Book: {
    value: "Book",
    name: "Book",
    description: null,
    children: null
  },
  Chapter: {
    value: "Chapter",
    name: "Chapter",
    description: null,
    children: null
  },
  Other: {
    value: "Other",
    name: "Other",
    description: null,
    children: null
  },
  Section: {
    value: "Section",
    name: "Section",
    description: null,
    children: null
  }
};
export const sectionTypes = {
  TR: [
    sectionTypeEntries.Article,
    sectionTypeEntries.Book,
    sectionTypeEntries.Chapter,
    sectionTypeEntries.Other,
    sectionTypeEntries.Section
  ],
  PR: [
    emptyEntry
  ],
  IR: [
    emptyEntry
  ],
  DR: [
    emptyEntry
  ]
};
const accessTypeEntries = {
  Controlled: {
    value: "Controlled",
    name: "Controlled",
    description: null,
    children: null
  },
  OaGold: {
    value: "OA_Gold",
    name: "OA_Gold",
    description: null,
    children: null
  },
  OtherFreeToRead: {
    value: "Other_Free_To_Read",
    name: "Other_Free_To_Read",
    description: null,
    children: null
  }
};
export const accessTypes = {
  TR: [
    accessTypeEntries.Controlled,
    accessTypeEntries.OaGold
  ],
  PR: [
    emptyEntry
  ],
  IR: [
    accessTypeEntries.Controlled,
    accessTypeEntries.OaGold,
    accessTypeEntries.OtherFreeToRead
  ],
  DR: [
    emptyEntry
  ]
};
const accessMethodEntries = {
  Regular: {
    value: "Regular",
    name: "Regular",
    description: null,
    children: null
  },
  TDM: {
    value: "TDM",
    name: "TDM",
    description: null,
    children: null
  }
};
export const accessMethods = {
  TR: [
    accessMethodEntries.Regular,
    accessMethodEntries.TDM
  ],
  PR: [
    accessMethodEntries.Regular,
    accessMethodEntries.TDM
  ],
  IR: [
    accessMethodEntries.Regular,
    accessMethodEntries.TDM
  ],
  DR: [
    accessMethodEntries.Regular,
    accessMethodEntries.TDM
  ]
};
const metricTypeEntries = {
  TotalItemInvestigations: {
    value: "Total_Item_Investigations",
    name: "Total_Item_Investigations",
    description: null,
    children: null
  },
  TotalItemRequests: {
    value: "Total_Item_Requests",
    name: "Total_Item_Requests",
    description: null,
    children: null
  },
  UniqueItemInvestigations: {
    value: "Unique_Item_Investigations",
    name: "Unique_Item_Investigations",
    description: null,
    children: null
  },
  UniqueItemRequests: {
    value: "Unique_Item_Requests",
    name: "Unique_Item_Requests",
    description: null,
    children: null
  },
  UniqueTitleInvestigations: {
    value: "Unique_Title_Investigations",
    name: "Unique_Title_Investigations",
    description: null,
    children: null
  },
  UniqueTitleRequests: {
    value: "Unique_Title_Requests",
    name: "Unique_Title_Requests",
    description: null,
    children: null
  },
  LimitExceeded: {
    value: "Limit_Exceeded",
    name: "Limit_Exceeded",
    description: null,
    children: null
  },
  NoLicense: {
    value: "No_License",
    name: "No_License",
    description: null,
    children: null
  },
  SearchesPlatform: {
    value: "Searches_Platform",
    name: "Searches_Platform",
    description: null,
    children: null
  },
  SearchesAutomated: {
    value: "Searches_Automated",
    name: "Searches_Automated",
    description: null,
    children: null
  },
  SearchesFederated: {
    value: "Searches_Federated",
    name: "Searches_Federated",
    description: null,
    children: null
  },
  SearchesRegular: {
    value: "Searches_Regular",
    name: "Searches_Regular",
    description: null,
    children: null
  }
};
export const metricTypes = {
  TR: [
    metricTypeEntries.TotalItemInvestigations,
    metricTypeEntries.TotalItemRequests,
    metricTypeEntries.UniqueItemInvestigations,
    metricTypeEntries.UniqueItemRequests,
    metricTypeEntries.UniqueTitleInvestigations,
    metricTypeEntries.UniqueTitleRequests,
    metricTypeEntries.LimitExceeded,
    metricTypeEntries.NoLicense
  ],
  PR: [
    metricTypeEntries.SearchesPlatform,
    metricTypeEntries.TotalItemInvestigations,
    metricTypeEntries.TotalItemRequests,
    metricTypeEntries.UniqueItemInvestigations,
    metricTypeEntries.UniqueItemRequests,
    metricTypeEntries.UniqueTitleInvestigations,
    metricTypeEntries.UniqueTitleRequests
  ],
  IR: [
    metricTypeEntries.TotalItemInvestigations,
    metricTypeEntries.TotalItemRequests,
    metricTypeEntries.UniqueItemInvestigations,
    metricTypeEntries.UniqueItemRequests,
    metricTypeEntries.LimitExceeded,
    metricTypeEntries.NoLicense
  ],
  DR: [
    metricTypeEntries.SearchesAutomated,
    metricTypeEntries.SearchesFederated,
    metricTypeEntries.SearchesRegular,
    metricTypeEntries.TotalItemInvestigations,
    metricTypeEntries.TotalItemRequests,
    metricTypeEntries.UniqueItemInvestigations,
    metricTypeEntries.UniqueItemRequests,
    metricTypeEntries.UniqueTitleInvestigations,
    metricTypeEntries.UniqueTitleRequests,
    metricTypeEntries.LimitExceeded,
    metricTypeEntries.NoLicense
  ]
};

const filters = {
  default: { dataType: [], sectionType: [], accessType: [], accessMethod: [], metricType: []},
  tr_b: { dataType: [dataTypeEntries.Book], sectionType: [], accessType: [], accessMethod: [accessMethodEntries.Regular], metricType: []},
  tr_j: { dataType: [dataTypeEntries.Journal], sectionType: [], accessType: [], accessMethod: [accessMethodEntries.Regular], metricType: []},
  dr: { dataType: [], sectionType: [], accessType: [], accessMethod: [accessMethodEntries.Regular], metricType: []},
  pr: { dataType: [], sectionType: [], accessType: [], accessMethod: [accessMethodEntries.Regular], metricType: []},
  ir: { dataType: [], sectionType: [], accessType: [], accessMethod: [accessMethodEntries.Regular], metricType: []},
}
export const defaultReportParameterFilter: {[index: string]: any} = {
  TR: filters.default,
  TR_B1: {...filters.tr_b, accessType: [accessTypeEntries.Controlled], metricType: [metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemRequests]},
  TR_B2: {...filters.tr_b, metricType: [metricTypeEntries.LimitExceeded, metricTypeEntries.NoLicense]},
  TR_B3: {...filters.tr_b, metricType: [metricTypeEntries.TotalItemInvestigations, metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemInvestigations, metricTypeEntries.UniqueItemRequests, metricTypeEntries.UniqueTitleInvestigations, metricTypeEntries.UniqueTitleRequests]},
  TR_J1: {...filters.tr_j, accessType: [accessTypeEntries.Controlled], metricType: [metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemRequests]},
  TR_J2: {...filters.tr_j, metricType: [metricTypeEntries.LimitExceeded, metricTypeEntries.NoLicense]},
  TR_J3: {...filters.tr_j, metricType: [metricTypeEntries.TotalItemInvestigations, metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemInvestigations, metricTypeEntries.UniqueItemRequests]},
  TR_J4: {...filters.tr_j, accessType: [accessTypeEntries.Controlled], metricType: [metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemRequests]},
  DR: filters.default,
  DR_D1: {...filters.dr, metricType: [metricTypeEntries.SearchesAutomated, metricTypeEntries.SearchesFederated, metricTypeEntries.SearchesRegular, metricTypeEntries.TotalItemInvestigations, metricTypeEntries.TotalItemRequests]},
  DR_D2: {...filters.dr, metricType: [metricTypeEntries.LimitExceeded, metricTypeEntries.NoLicense]},
  PR: filters.default,
  PR_P1: {...filters.pr, metricType: [metricTypeEntries.SearchesPlatform, metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemRequests, metricTypeEntries.UniqueTitleRequests]},
  IR: filters.default,
  IR_A1: {...filters.ir, dataType: [dataTypeEntries.Article], metricType: [metricTypeEntries.TotalItemRequests, metricTypeEntries.UniqueItemRequests]},
  IR_M1: {...filters.ir, dataType: [dataTypeEntries.Multimedia], metricType: [metricTypeEntries.TotalItemRequests]},
  default: filters.default,
}

const aggregation = {
  mandatory: {initialValue: true, allowed: false},
  optional: {initialValue: false, allowed: true},
  na: {initialValue: false, allowed: false}
}
const aggregations= {
  defaultNA: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.na, accessType: aggregation.na, accessMethod: aggregation.na, metricType: aggregation.mandatory }
}

const indexedReportParameterAggregations : {[index: string]: any} = {
  TR: { dataType: aggregation.optional, sectionType: aggregation.optional, YOP: aggregation.optional, accessType: aggregation.optional, accessMethod: aggregation.optional, metricType: aggregation.mandatory },
  TR_B1: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.mandatory, accessType: aggregation.na, accessMethod: aggregation.na, metricType: aggregation.mandatory },
  TR_B2: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.mandatory, accessType: aggregation.na, accessMethod: aggregation.na, metricType: aggregation.mandatory },
  TR_B3: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.mandatory, accessType: aggregation.mandatory, accessMethod: aggregation.na, metricType: aggregation.mandatory },
  TR_J1: aggregations.defaultNA,
  TR_J2: aggregations.defaultNA,
  TR_J3: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.na, accessType: aggregation.mandatory, accessMethod: aggregation.na, metricType: aggregation.mandatory },
  TR_J4: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.mandatory, accessType: aggregation.na, accessMethod: aggregation.na, metricType: aggregation.mandatory },
  DR: { dataType: aggregation.optional, sectionType: aggregation.na, YOP: aggregation.na, accessType: aggregation.na, accessMethod: aggregation.optional, metricType: aggregation.mandatory },
  DR_D1: aggregations.defaultNA,
  DR_D2: aggregations.defaultNA,
  PR: { dataType: aggregation.optional, sectionType: aggregation.na, YOP: aggregation.na, accessType: aggregation.na, accessMethod: aggregation.optional, metricType: aggregation.mandatory },
  PR_P1: aggregations.defaultNA,
  IR: { dataType: aggregation.optional, sectionType: aggregation.na, YOP: aggregation.optional, accessType: aggregation.optional, accessMethod: aggregation.optional, metricType: aggregation.mandatory },
  IR_A1: { dataType: aggregation.na, sectionType: aggregation.na, YOP: aggregation.na, accessType: aggregation.mandatory, accessMethod: aggregation.na, metricType: aggregation.mandatory },
  IR_M1: aggregations.defaultNA,
  default: aggregations.defaultNA,
}
export const reportParameters = {
  aggregations: indexedReportParameterAggregations,
  disabled: {
    TR: {
      dataType: false,
      sectionType: false,
      YOP: false,
      accessType: false,
      accessMethod: false,
      metricType: false,
      granularity: false
    },
    DR: {
      dataType: false,
      sectionType: true,
      YOP: true,
      accessType: true,
      accessMethod: false,
      metricType: false,
      granularity: false
    },
    PR: {
      dataType: false,
      sectionType: true,
      YOP: true,
      accessType: true,
      accessMethod: false,
      metricType: false,
      granularity: false
    },
    IR: {
      dataType: false,
      sectionType: true,
      YOP: false,
      accessType: false,
      accessMethod: false,
      metricType: false,
      granularity: false
    },
    default: {
      dataType: true,
      sectionType: true,
      YOP: true,
      accessType: true,
      accessMethod: true,
      metricType: true,
      granularity: false
    }
  }
};
