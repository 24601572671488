export const TRANSLATIONS_DE = {
    countryCode: {
        "en-US": "EN",
        "de-DE": "DE"
    },
    language: {
        current: "Deutsch",
        abridged: "DE"
    },
    base: {
        loading: "Inhalt wird geladen...",
        thiemeGroup: "Thieme Gruppe",
        noAccess: "Sie haben keinen Zugriff auf diesen Dienst.",
        pageTitle: "Counter Reporting",
        yes: "Ja",
        no: "Nein",
        logoutButtonText: "Abmelden"
    },
    report: {
        ui: {
            AccordionBasic: "Report",
            AccordionAdvanced: "Report Parameter",
            submitButton: "Generieren",
            month: "Monatlich",
            totals: "Gesamt",
        },
        messages: {
            generalError: "Da ist etwas schiefgegangen, bitte überprüfen Sie die Parameter oder kontaktieren Sie den Support.",
            reportMandatory: "Report ist ein Pflichtfeld",
        },
        basic: {
            from: "Von",
            to: "Bis",
            organization: "Organisation",
            platform: "Plattform",
            report: "Report",
        },
        params: {
            period: "Period",
            dataType: "Data Type",
            sectionType: "Section Type",
            YOP: "Veröffentlichungsjahr (YOP)",
            accessType: "Access Type",
            accessMethod: "Access Method",
            metricType: "Metric Type",
            excludeMonthlyDetails: "Granularität",
            aggregateByAlt: "Feld als Spalte anzeigen",
        },
    },
    footer: {
        privacy: {
            name: "Datenschutzerklärung",
            Url: "https://www.thieme.de/de/thieme-gruppe/Datenschutzerklaerung-Thieme-DE.htm",
        },
        imprint: {
            name: "Impressum",
            Url: "https://www.thieme.de/de/thieme-gruppe/Impressum-Thieme-DE.htm",
        }
    },
    apikeys: {
        heading: "SUSHI API Keys",
        createButtonText: "API Key erstellen",
        overview: {
            columns: {
                maskedKey: "Key",
                organisation: "Organisationen",
                createdOn: "Erstellt Am",
                validUntil: "Gültig Bis",
                lastUse: "Zuletzt Verwendet",
            },
            deleteButton: "API Key Löschen",
            noKeysMessage: "Es sind keine API Keys vorhanden"
        },
        create: {
            dialog: {
                text: "Bitte wählen Sie die Organisationen aus, für die Zugriff gewährt werden soll.",
                selectOrganisation: {
                    label: "Ausgewählte Organisationen"
                },
                buttonCreate: "API Key erstellen",
                buttonCancel: "Abbrechen",
                completed: {
                    title: "API Key erstellt",
                    text: "Ihr API Key wurde erfolgreich erstellt.",
                    fieldLabel: "API Key",
                    copyButton: "In die Zwischenablage kopieren",
                    closeButton: "Schließen",
                    copyTooltip: "In die Zwischenablage kopieren",
                    warning: {
                        title: "Wichtiger Hinweis",
                        text: "Bitte speichern Sie den API Key ab, bevor Sie das Fenster schließen. Der erstellte API Key wird Ihnen nur einmalig im Klartext angezeigt und ist nach Schließen des Fensters nicht mehr im Klartext abrufbar!"
                    },
                }
            }
        },
        delete: {
            dialog: {
                title: "API Key löschen",
                text: "Bitte bestätigen Sie die Löschung des API Keys.",
                confirmButton: "API Key löschen"
            }
        }
    }
};
