import React from "react";

import Reports from "./Reports";
import ApiKeys from "./ApiKeys";


export const routesConfig = [
    {path: '/', element: <Reports/>, linkName: 'Reports'},
    {
        path: 'apikeys',
        element: <ApiKeys/>,
        linkName: 'Manage API Keys',
    },
    {
        path: 'https://www.thieme.de/de/index.htm',
        linkName: 'Profile',
        external: true
    },
    {
        path: 'https://www.thieme-connect.de/products/all/contact',
        linkName: 'Support',
        external: true
    }
];
