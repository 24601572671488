import styles from "./Footer.module.scss";
import React from "react";
import Container from "@mui/material/Container";
import {Link, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {footerLinks} from "./FooterLinks";

export default function Footer() {
    const year = new Date().getFullYear();
    const {t} = useTranslation()
    const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

    const getTranslation = (useT: boolean, string: string) => {
        return useT ? t(string) : string
    }

    const links = (direction: "row" | "column") => (
        <Stack direction={direction}
               spacing={direction === "column" ? 0 : 3}
               className={direction === "column" ? styles.footer__container__mobile : undefined}
        >
            <Typography variant={"body1"}
                        lineHeight={"inherit"}
                        color={"#000"}
                        key={"copyright"}
            >
                Copyright {year} {t("base.thiemeGroup")}
            </Typography>
            {
                footerLinks.map(link =>
                    <Link underline="none"
                          className={styles.link}
                          key={getTranslation(link.useTranslation, link.path)}
                          href={getTranslation(link.useTranslation, link.path)}
                          title={getTranslation(link.useTranslation, link.linkName)}>
                        {getTranslation(link.useTranslation, link.linkName)}
                    </Link>
                )
            }
        </Stack>
    )

    return (
        <footer className={styles.footer}>
            <Container className={styles.footer__container}>
                {isMobile ? links("column") : links("row")}
            </Container>
        </footer>
    )
}
