export const footerLinks = [
    {
        path: 'footer.privacy.Url',
        linkName: 'footer.privacy.name',
        external: true,
        useTranslation: true,
    },
    {
        path: 'footer.imprint.Url',
        linkName: 'footer.imprint.name',
        external: true,
        useTranslation: true,
    }
];
