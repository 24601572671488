export interface ApiKey {
    id: string
    maskedKey: string
    organisations: { name: string, id: string }[]
    created: Date
    validUntil: Date
    lastUse: Date | null
}

export interface ApiKeyDTO {
    id: string
    maskedKey: string
    organisations: { name: string, id: string }[]
    created: string
    expires: string
    lastUse: string | null
}

export interface Organization {
    id: string
    name: string
}

export interface CreateApiKeyResponse {
    expires: Date,
    apiKey: string,
}

export class ApiKeysClient {
    private headers: Headers;

    constructor(token: string) {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${token}`);
        this.headers = headers;
    }

    fetchApiKeys(): Promise<ApiKey[]> {
        return fetch('/api/api-keys', {headers: this.headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`failed to fetch data: ${response.status}`)
                }

                return response.json() as Promise<ApiKeyDTO[]>
            })
            .then(apiKeys => {
                return apiKeys.map(key => {
                    return {
                        id: key.id,
                        maskedKey: key.maskedKey,
                        organisations: key.organisations,
                        created: new Date(key.created),
                        validUntil: new Date(key.expires),
                        lastUse: key.lastUse ? new Date(key.lastUse!) : null,
                    }
                })
            })
    }

    fetchOrganizations(): Promise<Organization[]> {
        return fetch('/api/reports/organizations', {headers: this.headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`failed to fetch data: ${response.status}`)
                }
                return response.json() as Promise<Organization[]>
            })
    }

    fetchReport(report: string, query: string): Promise<{fileName: string, file: Blob}> {
        return fetch(`/api/reports/${report.toLowerCase()}?${query}` , {headers: this.headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`failed to fetch data: ${response.status}`)
                }
                const filename = response.headers.get("content-disposition")?.split('filename=')[1].replaceAll('"', '')?? "report.xlsx";
                return response.blob().then(blob => {
                        return {fileName: filename, file: blob}
                    }
                )
            })
    }

    createApiKey(organizations: string[]): Promise<CreateApiKeyResponse> {
        return fetch("/api/api-keys", {
            method: 'POST',
            body: JSON.stringify({
                organizations: organizations,
                singleUse: false
            }),
            headers: this.headers
        }).then(response => response.json() as Promise<CreateApiKeyResponse>)
    }

    deleteApiKey(id: string) {
        return fetch(`/api/api-keys/${id}`, {
            method: 'DELETE',
            headers: this.headers
        })
    }

    fetchAccessRole(): Promise<boolean> {
        return fetch('/api/role-checks', {headers: this.headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error(`failed to fetch data: ${response.status}`)
                }
                return response.json() as Promise<boolean>
            })
    }
}
