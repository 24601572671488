import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import useOrganizations from "../../../hooks/UseOrganizations";
import React, {useState} from "react";
import {ModalProps} from "@mui/material/Modal";
import {useTranslation} from "react-i18next";
import styles from "./Creation.module.scss"
import {CreateApiKeyResponse} from "../../../clients/ApiKeysClient";
import {ContentCopy} from "@mui/icons-material";
import useApiKeysClient from "../../../hooks/UseApiKeysClient";

type CreationProps = {
    open: ModalProps['open'],
    onClose: (reason: string) => void
}

export default function Creation({open, onClose}: CreationProps) {
    const {t} = useTranslation()
    const organizations = useOrganizations()
    const apiKeysClient = useApiKeysClient()
    const [selectedOrganisations, setSelectedOrganizations] = useState<Array<string>>(organizations.map(o => o.id))
    const [createdApiKey, setCreatedApiKey] = useState<CreateApiKeyResponse | null>(null);
    const [isApiKeyCreated, setIsApiKeyCreated] = useState<boolean>(false)

    const orgSelected = (orgs: string | string[]) => {
        if (typeof orgs === 'string') {
            setSelectedOrganizations([orgs as string])
        } else {
            setSelectedOrganizations([...(orgs as string[])])
        }
    }

    const createApiKey = async () => {
        apiKeysClient.createApiKey(selectedOrganisations)
            .then(apiKey => {
                setCreatedApiKey(apiKey)
                setIsApiKeyCreated(true)
            })
    }

    const handleClose = () => {
        setSelectedOrganizations([])
        setCreatedApiKey(null)
        setIsApiKeyCreated(false)
        onClose('closed')
    }

    const copyToClipboardAdornment = () => (
        <InputAdornment position="end">
            <Tooltip title={t("apikeys.create.dialog.completed.copyTooltip")} disableFocusListener>
                <IconButton edge="end" onClick={() => navigator.clipboard.writeText(createdApiKey!.apiKey)}><ContentCopy /></IconButton>
            </Tooltip>
        </InputAdornment>
    )

    const chooseOrganisationsDialog = () => (
        <>
            <DialogTitle className={styles.dialog__title}>{t("apikeys.createButtonText")}</DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.dialog__description}>{t("apikeys.create.dialog.text")}</DialogContentText>
                <FormControl fullWidth className={styles.dialog__form}>
                    <InputLabel id="select-organisations-label">{t("apikeys.create.dialog.selectOrganisation.label")}</InputLabel>
                    <Select
                        value={selectedOrganisations}
                        label={t("apikeys.create.dialog.selectOrganisation.label")}
                        labelId="select-organisations-label"
                        onChange={(event) => orgSelected(event.target.value)}
                        renderValue={(selected) => organizations.filter(o => selected.includes(o.id)).map(o => `${o.name} (${o.id})`).join(', ')}
                        multiple
                    >
                        {organizations.map(org => (
                            <MenuItem key={org.id} value={org.id}>
                                <Checkbox checked={selectedOrganisations.includes(org.id)} />
                                <ListItemText primary={`${org.name} (${org.id})`} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Stack direction="row" spacing={2} justifyContent="flex-end" className={styles.dialog__buttons}>
                    <Button onClick={handleClose}>{t("apikeys.create.dialog.buttonCancel")}</Button>
                    <Button variant="contained" onClick={createApiKey}>{t("apikeys.create.dialog.buttonCreate")}</Button>
                </Stack>
            </DialogContent>
        </>
    )

    const createdDialog = () => (
        <>
            <DialogTitle className={styles.dialog__title}>{t("apikeys.create.dialog.completed.title")}</DialogTitle>
            <DialogContent>
                <Alert severity="warning">
                    <AlertTitle>{t("apikeys.create.dialog.completed.warning.title")}</AlertTitle>
                    {t("apikeys.create.dialog.completed.warning.text")}
                </Alert>
                <TextField
                    className={styles.dialog__apiKey}
                    variant="outlined"
                    label={t("apikeys.create.dialog.completed.fieldLabel")}
                    value={createdApiKey!.apiKey}
                    InputProps={{
                        endAdornment: copyToClipboardAdornment(),
                        readOnly: true
                    }}
                    fullWidth
                />
                <Stack direction="row" spacing={2} justifyContent="space-between" className={styles.dialog__buttons}>
                    <Button onClick={handleClose}>{t("apikeys.create.dialog.completed.closeButton")}</Button>
                    <Tooltip disableFocusListener title={t("apikeys.create.dialog.completed.copyTooltip")}>
                        <Button variant="contained" onClick={handleClose}>{t("apikeys.create.dialog.completed.copyButton")}</Button>
                    </Tooltip>
                </Stack>
            </DialogContent>
        </>
    )

    return (
        <Dialog open={open} onClose={handleClose} className={styles.dialog} fullWidth>
            {isApiKeyCreated ? createdDialog() : chooseOrganisationsDialog()}
        </Dialog>
    )
}
