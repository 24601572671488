import React from 'react';
import Container from '@mui/material/Container';

import Header from './components/general/Header';
import Footer from "./components/general/Footer";

import styles from './App.module.scss';
import {useRoutes} from "react-router-dom";

import {ThemeProvider} from "@mui/material/styles";
import Theme from "./Theme";
import CssBaseline from "@mui/material/CssBaseline";
import {useKeycloak} from "@react-keycloak/web";
import {routesConfig} from './routes/RoutesConfig';
import {Grid, StyledEngineProvider, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import useRoleAccess from "./hooks/UseRoleAccess";

export default function App() {
    const routesElement = useRoutes(routesConfig);
    const {t} = useTranslation()
    const isAuthenticated = useKeycloak().keycloak.authenticated;
    const isPermitted = useRoleAccess()

    const noAccess = (
        <Grid container sx={{marginTop: '1rem'}} rowGap={6}>
            <Grid item xs={12} md={12}>
                <Typography variant="h1">
                    {t("base.noAccess")}
                </Typography>
            </Grid>
        </Grid>
    )
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Theme}>
                <CssBaseline/>
                <div className={styles.app}>
                    <Header/>
                    <main>
                        <Container sx={{backgroundColor: "white", marginTop: 5, padding: 2}}>
                            {isAuthenticated && isPermitted !== undefined ?
                                isPermitted ? routesElement : noAccess
                                : <Typography variant={"h1"}>{t("base.loading")}</Typography>
                            }
                        </Container>
                    </main>
                    <Footer/>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
