import {ApiKey} from "../../../clients/ApiKeysClient";
import styles from "./Overview.module.scss";
import {
    Button,
    Collapse,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableRow,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Delete, DeleteOutlined, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import useApiKeysClient from "../../../hooks/UseApiKeysClient";

type ApiKeyRowProps = {
    apiKey: ApiKey,
    onDelete: (id: string) => void
}

export default function Row({apiKey, onDelete}: ApiKeyRowProps) {
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const apiKeyClient = useApiKeysClient()

    const handleDelete = () => {
        apiKeyClient.deleteApiKey(apiKey.id)
            .then(_success => onDelete(apiKey.id))
            .finally(() => setOpenDeleteDialog(false))

    }

    const deleteDialog = () => (
        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
            <DialogTitle className={styles.dialog__title}>{t("apikeys.delete.dialog.title")}</DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.dialog__content}>{t("apikeys.delete.dialog.text")}</DialogContentText>
                <Stack direction="row" justifyContent="flex-end">
                   <Button className={styles.dialog__button} variant="contained" color="error" onClick={handleDelete} endIcon={<Delete/>} fullWidth={isMobile}>{t("apikeys.delete.dialog.confirmButton")}</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )

    const row = () => (
        <TableRow hover>
            <Cell align="left">{apiKey.maskedKey}</Cell>
            <Cell align="right">{apiKey.organisations.map((org) => `${org.name} (${org.id})`).join(", ")}</Cell>
            <Cell align="right">{apiKey.created.toLocaleDateString(i18n.resolvedLanguage)}</Cell>
            <Cell align="right">{apiKey.validUntil.toLocaleDateString(i18n.resolvedLanguage)}</Cell>
            <Cell align="right">{apiKey.lastUse?.toLocaleDateString(i18n.resolvedLanguage)}</Cell>
            <Cell align="right"><IconButton color="primary" onClick={() => setOpenDeleteDialog(true)}><DeleteOutlined /></IconButton>{deleteDialog()}</Cell>
        </TableRow>
    )

    const rowMobile = () => (
        <>
        <TableRow selected={open}>
            <Cell align="left">{apiKey.maskedKey}</Cell>
            <Cell align="right">{apiKey.validUntil.toLocaleDateString(i18n.resolvedLanguage)}</Cell>
            <Cell align="right">
                <IconButton color="primary" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
            </Cell>
        </TableRow>
        <TableRow selected={open}>
            <Cell align="left" colSpan={3}>
                {open && <Divider light/>}
                <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <InnerCell sx={{ fontWeight: 'bold'}}>{t("apikeys.overview.columns.organisation")}:</InnerCell>
                                    <InnerCell>{apiKey.organisations.map(o => `${o.name} (${o.id})`).join(", ")}</InnerCell>
                                </TableRow>
                                <TableRow>
                                    <InnerCell sx={{ fontWeight: 'bold'}}>{t("apikeys.overview.columns.createdOn")}:</InnerCell>
                                    <InnerCell>{apiKey.created.toLocaleDateString(i18n.resolvedLanguage)}</InnerCell>
                                </TableRow>
                                <TableRow>
                                    <InnerCell sx={{ fontWeight: 'bold'}}>{t("apikeys.overview.columns.lastUse")}:</InnerCell>
                                    <InnerCell>{apiKey.lastUse?.toLocaleDateString(i18n.resolvedLanguage)}</InnerCell>
                                </TableRow>
                                <TableRow>
                                    <InnerCell sx={{ fontWeight: 'bold'}} colSpan={2}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            endIcon={<Delete/>}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        >{t("apikeys.overview.deleteButton")}</Button>
                                        {deleteDialog()}
                                    </InnerCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                </Collapse>
            </Cell>
        </TableRow>
        </>
    )

    const Cell = (props: TableCellProps) => (
        <TableCell padding="none" className={styles.table__cell} {...props} ></TableCell>)

    const InnerCell = (props: TableCellProps) => (
        <TableCell className={styles.table__innerCell} {...props} ></TableCell>)

    return (
        <>
            {isMobile ? rowMobile() : row()}
        </>
    )
}
