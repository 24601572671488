import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {ApiKeysClient} from "../clients/ApiKeysClient";

export default function useRoleAccess() {
    const [hasAccessRole, setAccessRole] = useState<boolean>()
    const token = useKeycloak().keycloak.token

    useEffect(() => {
        if (token === undefined) {
            return
        }

        const client = new ApiKeysClient(token);

        client.fetchAccessRole()
            .then(accessBool => {
                setAccessRole(accessBool);
            })
            .catch(err => {
                console.error("failed to fetch roles", err);
            })
    }, [token])

    return hasAccessRole
}


