import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ApiKeyOverview} from "../components/apikeys/Overview";
import Creation from "../components/apikeys/Creation";
import useApiKeysClient from "../hooks/UseApiKeysClient";
import {ApiKey} from "../clients/ApiKeysClient";


export default function ApiKeys() {
    const {t} = useTranslation();
    const [openCreationDialog, setOpenCreationDialog] = useState(false)
    const apiKeysClient = useApiKeysClient()
    const [apiKeys, setApiKeys] = useState<ApiKey[]>([])

    const loadApiKeys = () => {
        apiKeysClient.fetchApiKeys()
            .then(apiKeys => setApiKeys(apiKeys))
    }

    useEffect(() => {
        apiKeysClient.fetchApiKeys()
            .then(apiKeys => setApiKeys(apiKeys))
    }, [apiKeysClient])

    const handleCreationClose = () => {
        setOpenCreationDialog(false)
        loadApiKeys()
    }

    return (
        <Grid container sx={{marginTop: '1rem'}} rowGap={6}>
            <Grid item xs={6} md={9}>
                <Typography variant="h1">
                    {t("apikeys.heading")}
                </Typography>
            </Grid>
            <Grid item container xs={6} md={3} justifyContent="flex-end" alignItems="flex-end" alignContent="end">
                <Button fullWidth variant="contained" onClick={() => setOpenCreationDialog(true)}>{t("apikeys.createButtonText")}</Button>
                <Creation open={openCreationDialog} onClose={handleCreationClose}/>
            </Grid>
            <Grid item xs={12}>
                <ApiKeyOverview apiKeys={apiKeys} onDelete={loadApiKeys}></ApiKeyOverview>
            </Grid>
        </Grid>
    );
}
