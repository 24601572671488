import {createTheme} from '@mui/material/styles';
import {deDE, enUS} from '@mui/x-date-pickers/';

// A custom theme for this app
const Theme = createTheme({
        palette: {
            background: {
                paper: '#fff',
                default: '#eee'
            },
            primary: {
                main: '#013476',
            },
            secondary: {
                main: '#A3D8F8',
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: ['Thieme Argo Light', 'Roboto'].join(","),
            h1: {
                fontSize: 24,
                fontWeight: 'bold',
                fontFamily: ['Thieme Argo Bold', 'Roboto'].join(","),
            },
            h2: {
                fontSize: 20,
                fontWeight: 'bold',
                fontFamily: ['Thieme Argo Medium', 'Roboto'].join(","),
            },
            h4: {
                fontSize: 16,
                fontWeight: 'bold',
            },
            subtitle2: {
                fontSize: 16,
                color: '#aaa',
            },
        },
        components: {
            MuiContainer: {
                defaultProps: {
                    maxWidth: "xl"
                }
            },
            MuiSelect: {
                defaultProps: {
                    color: "primary",
                },
                styleOverrides: {
                    select: {
                        color: "#013476",
                    },
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "rgba(01, 52, 118, .65)",
                    },
                    root: {
                        "&:hover": {
                            "& > .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#013476"
                            }
                        }
                    }
                }
            }
        }
    },
    enUS, deDE);

export default Theme;
