import {
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme
} from '@mui/material'
import {useTranslation} from "react-i18next";
import styles from './Overview.module.scss';
import Row from "./Row";
import {ApiKey} from "../../../clients/ApiKeysClient";

function HeadCell(props: TableCellProps) {
    return (
        <TableCell {...props} padding="none" className={styles.table__head}></TableCell>
    )
}

type OverviewProps = {
    apiKeys: ApiKey[],
    onDelete: (id: string) => void
}

export default function Overview({apiKeys, onDelete}: OverviewProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const cols = () => (
        <TableRow>
            <HeadCell sx={{width: `25%`}} align="left">{t("apikeys.overview.columns.maskedKey")}</HeadCell>
            <HeadCell sx={{width: `25%`}} align="right">{t("apikeys.overview.columns.organisation")}</HeadCell>
            <HeadCell sx={{width: `10%`}} align="right">{t("apikeys.overview.columns.createdOn")}</HeadCell>
            <HeadCell sx={{width: `10%`}} align="right">{t("apikeys.overview.columns.validUntil")}</HeadCell>
            <HeadCell sx={{width: `20%`}} align="right">{t("apikeys.overview.columns.lastUse")}</HeadCell>
            <HeadCell sx={{width: `10%`}} align="right"></HeadCell>
        </TableRow>
    )

    const colsMobile = () => (
        <TableRow>
            <HeadCell sx={{width: `50%`}} align="left">{t("apikeys.overview.columns.maskedKey")}</HeadCell>
            <HeadCell sx={{width: `40%`}} align="right">{t("apikeys.overview.columns.validUntil")}</HeadCell>
            <HeadCell sx={{width: `10%`}} align="right"></HeadCell>
        </TableRow>
    )

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    {isMobile ? colsMobile() : cols()}
                </TableHead>
                <TableBody>
                    {apiKeys.length > 0
                        ? apiKeys!.map(k => <Row key={k.id} apiKey={k} onDelete={onDelete} ></Row>)
                        : <TableRow>
                            <TableCell padding="none" className={styles.table__cell} colSpan={isMobile ? 3 : 6}>{t("apikeys.overview.noKeysMessage")}</TableCell>
                          </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
