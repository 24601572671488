import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {ApiKeysClient, Organization} from "../clients/ApiKeysClient";

export default function useOrganizations() {
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const token = useKeycloak().keycloak.token

    useEffect(() => {
        if (token === undefined) {
            console.error("failed to get organizations: no token")
            setOrganizations([])
            return
        }

        const client = new ApiKeysClient(token);

        client.fetchOrganizations()
            .then(fetchedOrganizations => {
                setOrganizations(fetchedOrganizations);
            })
            .catch(err => {
                console.error("failed to fetch organizations", err);
            })
    // disabled warning because it will fail build, but adding token as dependency will cause unwanted
    // organization fetches after a token has been refreshed
    // eslint-disable-next-line
    }, [])

    return organizations
}


