import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {ApiKeysClient} from "../clients/ApiKeysClient";

export default function useApiKeysClient() {
    const [apiKeysClient, setApiKeysClient] = useState<ApiKeysClient>(new ApiKeysClient(""))
    const token = useKeycloak().keycloak.token

    useEffect(() => {
        if (token === undefined) {
            console.error("failed to get apiKeys: no token")
            return
        }

        setApiKeysClient(new ApiKeysClient(token))
    }, [token])

    return apiKeysClient
}
