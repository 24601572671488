import React from 'react';
import ThiemeLogo from './images/thieme-logo.svg';
import styles from './Header.module.scss';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {routesConfig} from "../../../routes/RoutesConfig";
import {NavLink as RouterNavLink, NavLinkProps} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Divider, Drawer, Link, Stack, useMediaQuery, useTheme} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import {useKeycloak} from "@react-keycloak/web";
import {Logout} from "@mui/icons-material";

export default function Header() {
    const {t, i18n} = useTranslation()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
    const {keycloak} = useKeycloak();
    const isAuthenticated = keycloak.authenticated

    const changeLanguageHandler = () => {
        (i18n.resolvedLanguage === "en-US") ? i18n.changeLanguage("de-DE").then() : i18n.changeLanguage("en-US").then();
    }

    const NavLink = React.forwardRef<any, NavLinkProps>(
        (props, ref) =>
            <RouterNavLink
                ref={ref}
                {...props}
                className={({isActive}) => [styles.link, isActive ? styles['link--active'] : undefined, props.className].join(" ")}
            />
    )

    const links = (direction: "row" | "column") => (
        <Stack direction={direction}
               sx={{"& hr": {my: 1}}}
               spacing={direction === "column" ? 0 : 1}
               className={direction === "column" ? styles['link--mobileSpacing'] : undefined}
        >
            {
                routesConfig.map(route =>
                    route.external ?
                        (
                            <Link underline="none"
                                  className={styles.link}
                                  key={route.path}
                                  href={route.path}
                                  title={route.linkName}>
                                {route.linkName}
                            </Link>
                        )
                        :
                        (
                            <Link component={NavLink}
                                  key={route.path}
                                  to={route.path}
                                  underline={"none"}
                                  title={route.linkName}
                            >
                                {route.linkName}
                            </Link>
                        )
                )
            }
            <Divider
                orientation={direction === "row" ? "vertical" : "horizontal"}
                variant={direction === "row" ? "middle" : "fullWidth"}
                className={styles.divider}
                flexItem
            />
            <span
                className={styles.header__language}
                onClick={changeLanguageHandler}
            >
                <span className={i18n.resolvedLanguage === "de-DE" ? styles.active : undefined}>
                    {t(`countryCode.de-DE`)}
                </span>
                <span>&nbsp;/&nbsp;</span>
                <span className={i18n.resolvedLanguage === "en-US" ? styles.active : undefined}>
                    {t(`countryCode.en-US`)}
                </span>

            </span>
            {(isAuthenticated ? <Button variant="text" className={styles.logout} startIcon={<Logout />} onClick={() => keycloak.logout()}>{t(`base.logoutButtonText`)}</Button> : <></> )}
        </Stack>
    )

    const nav = () => {
        if (isMobile) {
            return <>
                <MenuIcon className={styles.header__burger} onClick={() => setMobileNavOpen(!mobileNavOpen)}/>
                <Drawer
                    open={mobileNavOpen}
                    anchor="left"
                    onClose={() => setMobileNavOpen(false)}
                >
                    <Box className={styles.header__drawer} onClick={() => setMobileNavOpen(false)}>
                        {links("column")}
                    </Box>
                </Drawer>
            </>
        } else {
            return links("row")
        }
    }

    return (
        <header data-testid={"header"} className={styles.header}>
            <div className={styles.header__top}/>
            <div className={styles.header__toolbar}>
                <Container>
                    <img src={ThiemeLogo} alt="Thieme Logo"/>
                    <Typography variant="h1" noWrap>
                        {t("base.pageTitle")}
                    </Typography>
                </Container>
            </div>
            <div className={styles.header__nav}>
                <Container>
                    {nav()}
                </Container>
            </div>
        </header>
    )
}
