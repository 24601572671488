import Keycloak from 'keycloak-js';

const keycloak = initKeycloak()

function initKeycloak() {
    if (process.env.NODE_ENV === "development") {
        return new Keycloak({
            url: "https://authentication-e.thieme.de",
            realm: "master",
            clientId: "usst-counter-ui"
        })
    } else return new Keycloak('/config/keycloak.json')
}

export default keycloak
